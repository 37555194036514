<script>
  import { onMount } from "svelte";
  import Button from "@elements/Button.svelte";

  export let title = "";

  let buttonProps = {};

  onMount(() => {
    buttonProps = {
      leftIcon: { name: "share" },
      text: "Share",
      variant: "icon",
      shareData: {
        title: title,
        text: `Watch ${title}`,
        url: window.location.href,
      },
    };
  });
</script>

<Button {...buttonProps} color="white" />
